.feedbackForm {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.feedbackForm__container {
    display: flex;
    width: 100%;
    max-width: 370px;
    flex-direction: column;
    align-items: center;
}

feedbackForm__container input {
    width: 100%;

}